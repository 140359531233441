import React from 'react'
import Resources from '../../../data/services/resources'
import moment from 'moment-timezone'
import {getLookup, returnSameKeyValueObject} from '../../../common/util/util-helpers'
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../util/util-constants";
import Card from "../../../common/components/card";
import CardSubTitle from "../../../common/components/card/card-sub-title";
import {LoaderLarge} from "../../../common/components/loader";
import {fieldsToHtml} from "../../../common/util/util-fields";

const InfoTab = ({fields_info, translate, isLoading, handleInputChange}) => {
    const selects = {
        OfficeID: {
            api: 'api/' + Resources.OfficesQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.OfficeName,
                value: item.OfficeID
            })
        },
        Year: returnSameKeyValueObject(1900, moment().year() + 1),
        TruckOwnershipTypeID: getLookup('TruckOwnershipType', 'TruckOwnershipTypeID', 'TruckOwnershipType'),
        TrackingMethodID: getLookup('TrackingMethod'),
        OwnedByOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.OrganizationID,
                label: item.LegalName
            })
        },
        OwnedByContactID: {
            api: 'api/' + Resources.ContactsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + " " + item.LastName
            })
        },
        ContactGroupID: {
            api: 'api/' + Resources.ContactGroupsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.ContactGroupName,
                value: item.ContactGroupID
            })
        },
    };

    const fieldsHtmlBasicInfo = fieldsToHtml(Object.values(Object.assign({}, fields_info)).slice(0, 13), translate, handleInputChange, selects)
    const fieldsHtmlProfile = fieldsToHtml(Object.values(Object.assign({}, fields_info)).slice(14, 20), translate, handleInputChange, selects);
    const fieldsHtmlNotes = fieldsToHtml(Object.values(Object.assign({}, fields_info)).slice(20, 22), translate, handleInputChange, selects);
    const fieldsHtmlOwnership = fieldsToHtml(Object.values(Object.assign({}, fields_info)).slice(22, 28), translate, handleInputChange, selects);

    return (
        <React.Fragment>
            <div className="pb-20">
                <div className="relative grid grid-cols-12 gap-4 max-w-4xl 3xl:max-w-full mx-auto 3xl:mx-0">
                    <div className="col-span-full 3xl:col-span-6">
                        <Card addClass={'h-full relative'}>
                            <CardSubTitle
                                text={translate('text.BasicInfo')}
                            />
                            <div className="grid grid-cols-6 gap-5">
                                {fieldsHtmlBasicInfo}
                            </div>

                            {isLoading && (
                                <LoaderLarge stripesBg/>
                            )}
                        </Card>
                    </div>

                    <div className="col-span-full 3xl:col-span-6">
                        <Card addClass={'h-full relative'}>
                            <CardSubTitle
                                text={translate('text.Notes')}
                            />
                            <div className="space-y-5">
                                {fieldsHtmlNotes}
                            </div>

                            {isLoading && (
                                <LoaderLarge stripesBg/>
                            )}
                        </Card>
                    </div>

                    <div className="col-span-full 3xl:col-span-6">
                        <Card addClass={'h-full relative'}>
                            <CardSubTitle
                                text={translate('text.Profile')}
                            />
                            <div className="grid grid-cols-6 gap-5">
                                {fieldsHtmlProfile}
                            </div>

                            {isLoading && (
                                <LoaderLarge stripesBg/>
                            )}
                        </Card>
                    </div>

                    <div className="col-span-full 3xl:col-span-6">
                        <Card addClass={'h-full relative'}>
                            <CardSubTitle
                                text={translate('text.OwnerShipAndValue')}
                            />
                            <div className="grid grid-cols-6 gap-5">
                                {fieldsHtmlOwnership}
                            </div>

                            {isLoading && (
                                <LoaderLarge stripesBg/>
                            )}
                        </Card>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default InfoTab
