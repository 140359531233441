import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {EyeIcon} from '@heroicons/react/24/outline'
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ProductServiceInfoDialog from "../../../common/components/modal/product-service-info-dialog";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import Button from '../../../common/components/button'
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'
import {formatMoney} from "../../../common/util/util-formaters";
import moment from "moment-timezone";
import {getThirdResource} from "../../../data/actions/thirdResource";
import InvoiceExpenseDetailsDialog from "../dialogs/invoice-expense-details-dialog";
import ModalDefault from "../../../common/components/modal/modal-default";

class ProductsServicesTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'ProductService',

            // Fields
            tableFields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            // Dialog(s)
            selectedItem: {},
            reportModalOpen: false,
            confirmDialog: false,
            selectedInvoiceExpenseItem: false
        }

        this.pagePath = this.props.location.pathname.substring(1) + '_products'
        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.ProductsServices,
            query: this.getQuery()
        }))
    }

    archiveResource = (item) => {

        this.setState({text: this.props.translate('message.confirm_archive_generic') + ` ${item.ProductService}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({
                            ProductServiceID: item.ProductServiceID
                        }, this.getQuery()),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.ProductService]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {

        this.setState({text: this.props.translate('message.confirm_restore_generic') + ` ${item.ProductService}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            ProductServiceID: item.ProductServiceID,
                            ArchivedDate: 1
                        },
                        errorMessage: true, successMessage: `Products Service '${item.ProductService}' restored`,
                        query: this.getQuery(),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.ProductsServices,
            query: Object.assign(
                {format: 'EXCEL', name: 'ProductsServices_' + currentDate() + '.xlsx'},
                this.getQuery()
            )
        }))
    }

    /** UI Events
     ================================================================= */
    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: true
        })
    }
    handleToggleReportModalOpen = (selectedItem) => {
        this.setState({
            selectedItem: selectedItem,
            reportModalOpen: !this.state.reportModalOpen
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    toggleProductServiceDialog = (selectedItem = {}) => {
        this.setState({
            selectedItem: selectedItem,
            isProductServiceInfoDialogVisible: !this.state.isProductServiceInfoDialogVisible
        })
    }

    handleEditClick = (item) => {
        this.setState({selectedItem: item, isProductServiceInfoDialogVisible: true})
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'query', 'offset', 'paginationPage', 'showArchived'])
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            ProductServiceTypeID: new Field('ProductServiceTypeID', '', [''], false, 'select', {}, {
                all: true
            }),
            ProductServiceCategoryID: new Field('ProductServiceCategoryID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            OutOfStock: new Field('OutOfStock', '', [''], false, 'checkbox'),
            LowOnStock: new Field('LowOnStock', '', [''], false, 'checkbox'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getFields = () => {
        return {
            ProductService: new Field('ProductService', '', ['empty'], false, 'text'),
            ProductServiceCategoryID: new Field('ProductServiceCategoryID', '', ['empty'], false, 'select-search'),
            ProductServiceTypeID: new Field('ProductServiceTypeID', '', ['empty'], false, 'select-search'),
            IncomeAccountID: new Field('IncomeAccountID', '', ['empty'], false, 'select-search'),
            ExpenseAccountID: new Field('ExpenseAccountID', '', ['empty'], false, 'select-search')
        }
    }

    getResource = () => {
        return Resources.ProductsServices
    }

    handleToggleViewInvoiceExpense(item = null) {
        this.setState({
            selectedInvoiceExpenseItem: item
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <React.Fragment>
                <div className="sm:flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="ml-auto flex sm:justify-start justify-end items-center">
                        <div className={'flex mr-4'}>
                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>


                        <Button
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={() => {
                                this.toggleProductServiceDialog()
                            }}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard addClass={'relative'}>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={resource.isLoading}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        actions={[
                            {
                                action: (it, i) => this.handleToggleReportModalOpen(it, i),
                                icon: EyeIcon,
                                visible: () => checkPerm(Resources.ProductServiceReport, READ_PERM)
                            }]
                        }

                        onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleEditClick : null}
                        onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleEditClick : null}
                        onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                        onRestore={checkPerm(this.getResource(), UPDATE_PERM) ? this.restoreFromArchive : null}
                    />

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecordsTable
                        show={(data.length === 0) && !resource.isLoading}
                        canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                        title={'No matching records found'}
                        text={'Create new Products or service'}
                        btnLabel="Create products or service"
                        onBtnClick={() => {
                            this.toggleProductServiceDialog({ProductService: this.state.queryFilterFields.query.value})
                        }}
                        onClearFilterClick={this.handleClearFiltersClick}
                        clearFilterBtnLabel={translate('text.clear_all_filters')}
                        clearFilterText={translate('text.try_without_filters')}
                        filters={this.state.queryFilterFields}
                    />
                </TableCard>

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                {this.state.isProductServiceInfoDialogVisible && (
                    <ProductServiceInfoDialog
                        {...this.props}
                        query={this.getQuery()}
                        editedItem={this.state.selectedItem}
                        onCloseProductServiceInfoDialog={this.toggleProductServiceDialog}
                        name={this.state.ProductServiceInfoName}
                    />
                )}

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <ResourceTableDialog
                    widthClass={'max-w-6xl'}
                    show={this.state.reportModalOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.ProductServiceReport}
                    title={'Inventory report'}
                    dispatch={this.props.dispatch}
                    isFooterVisible={true}
                    onView={(item) => {
                        this.handleToggleViewInvoiceExpense(item)
                    }}
                    hasViewPerm={checkPerm(Resources.ExpenseExpense, READ_PERM) && checkPerm(Resources.InvoicesInvoice, READ_PERM)}
                    defaultAction={(item) => {
                        if (item.Type === 1 && !checkPerm(Resources.ExpenseExpense, READ_PERM)) {
                            return false;
                        }

                        if (item.Type !== 1 && !checkPerm(Resources.InvoicesInvoice, READ_PERM)) {
                            return false;
                        }
                        this.handleToggleViewInvoiceExpense(item)
                    }}
                    limit={50}
                    fields={{
                        Date: new Field('Date', '', [], false, 'date', {
                            omitSort: true
                        }),
                        Description: new Field('Description', '', [''], false, 'textarea', {omitSort: true}),
                        Type: new Field('Type', '', [''], false, 'custom', {
                            render: (it) => {
                                return it.Type === 1 ? 'Bought' : 'Sold'
                            },
                            omitSort: true
                        }),
                        FromTo: new Field('FromTo', '', [''], false, 'custom', {
                            render: (it) => it.FromTo
                        }),
                        Amount: new Field('Amount', '', [''], false, 'money', {omitSort: true}),
                        Qty: new Field('Qty', '', [''], false, 'float', {omitSort: true}),
                        TaxID: new Field('TaxID', '', [''], false, 'select-search', {omitSort: true}),
                        TotalAmount: new Field('TotalAmount', '', [''], false, 'money', {omitSort: true})
                    }}

                    fieldsFilter={{
                        StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-4'}, {}),
                        EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-4'}, {}),
                        OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {addContainerClass: 'col-span-4'}, {
                            isClearable: true
                        }),
                    }}

                    htmlBefore={(
                        <div className={"p-2 text-lg"}>
                            Current profit for the filtered records: {formatMoney(this.props.dialogResource?.data?.TotalTotalAmount ?? 0)}
                        </div>
                    )}

                    tableKey="Name"
                    defaultQuery={{id: this.state?.selectedItem?.ProductServiceID ?? null}}
                    onClose={this.handleToggleReportModalOpen}
                    translate={translate}
                    selects={{
                        OrganizationID: {
                            api: 'api/' + Resources.OrganizationsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                value: item.OrganizationID,
                                label: item.LegalName,
                                Organization: item
                            })
                        }
                    }}
                    htmlAfter={() => {

                    }}
                />

                <ModalDefault
                    show={!!this.state.selectedInvoiceExpenseItem}
                    widthClass={'max-w-5xl w-screen'}
                    title={"Details"}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleViewInvoiceExpense()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.thirdResource}
                        isLoading={this.props.thirdResource.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        showLoadReferenceLinks={true}

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}

                        // Data events
                        onFetchData={() => {
                            if (this.state.selectedInvoiceExpenseItem) {
                                this.props.dispatch(getThirdResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        [this.state.selectedInvoiceExpenseItem.Type === 1 ? "ExpenseID" : "InvoiceID"]: this.state.selectedInvoiceExpenseItem.ID
                                    },
                                    resource: this.state.selectedInvoiceExpenseItem.Type === 1 ? Resources.ExpenseExpense : Resources.InvoicesInvoice
                                }))
                            }
                        }}
                    />
                </ModalDefault>
            </React.Fragment>
        )
    }
}

export default connect(state => state)(ProductsServicesTab)
