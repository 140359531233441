import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    openInNewTab,
    returnMileage,
    returnMileageText,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {download} from '../../../data/actions/download'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import Tippy from '@tippyjs/react'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    DISTANCE_UNIT_KILOMETERS_ID,
    DISTANCE_UNIT_MILES_ID,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import PresentationChartLineIcon from "@heroicons/react/24/outline/PresentationChartLineIcon";
import {numberWithCommas} from "../../../util/util-formaters";
import CubeIcon from "@heroicons/react/24/outline/CubeIcon";
import CubeTransparentIcon from "@heroicons/react/24/outline/CubeTransparentIcon";
import MapPinIconDark from "@heroicons/react/20/solid/MapPinIcon";
import MapPinIconLight from "@heroicons/react/24/outline/MapPinIcon";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import Layout from "../../../common/components/layout";
import WidgetStats from "../../../common/components/widgets/widget-stats";
import {fillFieldsFromData} from "../../../common/util/util-fields";

class MileageView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,

            sort: 'DESC',
            sortBy: 'Date',
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

            isColumnsDialogVisible: false,

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),

            // Dialogs
            selectedItem: null,
            confirmDialog: false,

            areStatsVisible: LocalStorage.has(this.pageSettingsName) ? JSON.parse(LocalStorage.get(this.pageSettingsName) ?? {})?.areStatsVisible : true,
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'mileage' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'mileage_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    archiveResource = (item) => {
        this.setState({text: this.props.translate('message.confirm_delete_mileage')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: {LoadMileageID: item.LoadMileageID},
                        piggyQuery: this.getQuery(),
                        errorMessage: true, successMessage: this.props.translate(`message.mileage_deleted`),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value, who) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    toggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen,
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleStatsToggleClick = () => {
        this.setState({areStatsVisible: !this.state.areStatsVisible}, () => {
            let pageSettings = {}

            if (LocalStorage.has(this.pageSettingsName)) {
                pageSettings = JSON.parse(LocalStorage.get(this.pageSettingsName))
            }

            Object.assign(pageSettings, {areStatsVisible: this.state.areStatsVisible})
            LocalStorage.set(this.pageSettingsName, JSON.stringify(pageSettings))
        })
    }
    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [], false, 'search'),

            StartDate: new Field('StartDate', '', [], false, 'date'),
            EndDate: new Field('EndDate', '', [], false, 'date'),
            LoadID: new Field('LoadID', '', [], false, 'select-search'),
            DriverID: new Field('DriverID', '', [], false, 'select-search'),
            TruckID: new Field('TruckID', '', [], false, 'select-search'),
            Taxable: new Field('Taxable', '', [], false, 'checkbox'),
            Empty: new Field('Empty', '', [], false, 'checkbox'),
            Thruway: new Field('Thruway', '', [], false, 'checkbox'),
            StateID: new Field('StateID', '', [], false, 'select'),

            limit: new Field('limit', 10, [], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    getFields = (item = null) => {
        let fieldTemplates = {
            Date: new Field('Date', '', ['empty'], false, 'date', {addContainerClass: 'col-span-3'}),
            LoadID: new Field('LoadID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-3',
                omitSort: true,
                render: (item) => {
                    if (!item.LoadID) {
                        return null;
                    }
                    return (
                        <button
                            onClick={(e) => {
                                if (checkPerm(Resources.LoadInfo, READ_PERM)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openInNewTab(`/loads/info/${item.LoadID}`);
                                }
                            }}
                            disabled={!checkPerm(Resources.LoadInfo, READ_PERM)}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.LoadNumber}
                        </button>
                    )
                }
            , label:"LoadNumber"}),
            DriverID: new Field('DriverID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-3',
                render: (item) => {
                    if (!item.DriverID) return null;

                    return (
                        <button
                            onClick={(e) => {
                                if (checkPerm(Resources.DriverInfo, READ_PERM)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openInNewTab(`/drivers/info/${item.DriverID}`, "_blank");
                                }
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.Driver}
                        </button>
                    )
                }
            }),
            CoDriverID: new Field('CoDriverID', '', [], false, 'select-search', {
                addContainerClass: 'col-span-3',
                render: (item) => {
                    if (!item.CoDriverID) return null;

                    return (
                        <button
                            onClick={(e) => {
                                if (checkPerm(Resources.DriverInfo, READ_PERM)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openInNewTab(`/drivers/info/${item.DriverID}`, "_blank");
                                }
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.CoDriver}
                        </button>
                    )
                }
            }),
            TruckID: new Field('TruckID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-3',
                render: (item) => {
                    if (!item.TruckID) return null;

                    return (
                        <button
                            onClick={(e) => {
                                if (checkPerm(Resources.TrucksInfo, UPDATE_PERM)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openInNewTab(`/trucks/info/${item.TruckID}`);
                                }
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.Truck}
                        </button>
                    )
                }
            }),
            TrailerID: new Field('TrailerID', '', [], false, 'select-search', {
                addContainerClass: 'col-span-3',
                render: (item) => {
                    if (!item.TrailerID) return null;

                    return (
                        <button
                            onClick={(e) => {
                                if (checkPerm(Resources.Trailer, UPDATE_PERM)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openInNewTab(`/trailers/info/${item.TrailerID}`);
                                }
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.Trailer}
                        </button>
                    )
                }
            }, {
                isClearable: true,
            }),
            NonTollMiles: new Field('NonTollMiles', '', ['float'], false, 'float', {
                addContainerClass: 'col-span-3',
                render: (item) => <div
                    className={"text-right"}>{returnMileage(item.NonTollMiles)} {returnMileageText() ?? this.props.translate("text." + returnMileageText())}</div>
            }),
            TollMiles: new Field('TollMiles', '', ['float'], false, 'float', {
                addContainerClass: 'col-span-3',
                render: (item) => <div
                    className={"text-right"}>{returnMileage(item.TollMiles)} {returnMileageText() ?? this.props.translate("text." + returnMileageText())}</div>
            }),
            Taxable: new Field('Taxable', '', [''], false, 'checkbox', {addContainerClass: 'col-span-2'}),
            Empty: new Field('Empty', '', [''], false, 'checkbox', {addContainerClass: 'col-span-2'}),
            Thruway: new Field('Thruway', '', [''], false, 'checkbox', {addContainerClass: 'col-span-2'}),
            StateID: new Field('StateID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, item)

        if (item) {
            fieldTemplates['NonTollMiles'].value = returnMileage(item.NonTollMiles)
            fieldTemplates['TollMiles'].value = returnMileage(item.TollMiles)
        }

        return fieldTemplates
    }

    getMileage = (Mileage) => {
        if ((+LocalStorage.get('user')?.Contact?.DistanceUnitID) === DISTANCE_UNIT_KILOMETERS_ID) {
            return (Mileage * 0.621371192).toFixed(2)
        } else if ((+LocalStorage.get('user')?.Contact?.DistanceUnitID) === DISTANCE_UNIT_MILES_ID) {
            return Mileage
        }
    }

    saveFilters = () => {
        let filters = []

        Object.keys(this.getQuery()).forEach(it => filters.push(it))

        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    setTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    getResource = () => {
        return Resources.Mileage
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const isLoading = resource.isLoading

        const metadata = {
            LoadID: {
                api: 'api/' + Resources.LoadsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.LoadID,
                    label: item.LoadNumber
                })
            },
            DriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.Driver
                })
            },
            CoDriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.Driver
                })
            },
            TruckID: {
                api: 'api/' + Resources.TrucksQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.TruckID,
                    label: item.Truck
                })
            },
            TrailerID: {
                api: 'api/' + Resources.TrailersQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.TrailerID,
                    label: item.Trailer
                })
            }
        }

        const TotalTollMiles = getProp(resource, 'data.TotalTollMiles', 0)
        const TotalNonTollMiles = getProp(resource, 'data.TotalNonTollMiles', 0)
        const LoadedMileage = getProp(resource, 'data.LoadedMileage', 0)
        const EmptyMileage = getProp(resource, 'data.EmptyMileage', 0)

        const stats = [
            {
                id: 1,
                name: translate('text.TotalTollMiles'),
                icon: MapPinIconDark,
                value: returnMileage(TotalTollMiles),
                valueSuffix: ' miles',
                decimalCount: 0
            },
            {
                id: 2,
                name: translate('text.TotalNonTollMiles'),
                value: returnMileage(TotalNonTollMiles),
                icon: MapPinIconLight,
                valueSuffix: ' miles',
                decimalCount: 0
            },
            {
                id: 3,
                name: translate('text.TotalMileage'),
                value: returnMileage(LoadedMileage + EmptyMileage),
                valueSuffix: ' miles',
                decimalCount: 0,
                icon: MapPinIconDark,
                more: [
                    {
                        id: 1,
                        name: translate('text.LoadedMileage'),
                        amount: numberWithCommas(returnMileage(LoadedMileage), 0),
                        icon: CubeIcon
                    },
                    {
                        id: 2,
                        name: translate('text.EmptyMileage'),
                        amount: numberWithCommas(returnMileage(EmptyMileage), 0),
                        decimalCount: 0,
                        icon: CubeTransparentIcon
                    },
                ]
            },
        ]

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.mileage_view')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleToggleCreateModal()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    >
                        <Tippy
                            content={translate(this.state.areStatsVisible ? 'text.hide_stats' : 'text.show_stats')}
                            delay={[400, 0]}
                            trigger="mouseenter"
                        >
                            <button
                                className={
                                    classNames(
                                        this.state.areStatsVisible ? 'text-primary border-primary' : undefined,
                                        'btn btn-header'
                                    )
                                }
                                onClick={this.handleStatsToggleClick}
                            >
                                <PresentationChartLineIcon
                                    className="w-5 h-5"
                                />
                            </button>
                        </Tippy>
                    </PageHeader>

                    {this.state.areStatsVisible && (
                        <div className="relative z-40">
                            <div className="grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-3">
                                <WidgetStats
                                    stats={stats}
                                />
                            </div>
                        </div>
                    )}

                    <div className="flex relative z-[32]">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            {/*<Tippy content={translate('text.download_template')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcelTemplate}
                                >
                                    <RectangleGroupIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>*/}

                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.toggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard id="table-card">
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={isLoading}
                        />

                        <NoRecordsTable
                            show={(data.length === 0) && !isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={translate('text.no_matching_records')}
                            className={"pb-12 pt-16 px-6"}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleToggleEditModel : null}
                            onEdit={this.handleToggleEditModel}
                            onDelete={this.archiveResource}

                            hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}
                            hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}

                            metadata={metadata}
                        />

                        <TableCardFooter
                            show={!(!data.length && !isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    </TableCard>
                </Page>

                <ModalSaveResource
                    title={translate('text.EditMileage')}
                    widthClass="max-w-xl"
                    gridColsClass="grid-cols-6"
                    errorMessage={this.state.errorMessage}
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.LoadMileageID = this.state.selectedItem.LoadMileageID
                            params.TollMiles = this.getMileage(params.TollMiles)
                            params.NonTollMiles = this.getMileage(params.NonTollMiles)
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: translate('message.mileage_updated'),
                            }))
                            this.handleToggleEditModel()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={translate('text.CreateMileage')}
                    widthClass="max-w-xl"
                    gridColsClass="grid-cols-6"
                    errorMessage={this.state.errorMessage}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.TollMiles = this.getMileage(params.TollMiles)
                            params.NonTollMiles = this.getMileage(params.NonTollMiles)
                            params.LoadID = params.LoadNumber;
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: translate('message.mileage_created'),
                            }))
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    title={translate('text.Confirm')}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.setTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.toggleColumnSettingsDialog}
                    translate={translate}
                />
            </Layout>
        )
    }
}

export default connect(state => state)(MileageView)
