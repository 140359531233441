import LocalStorage from "../../util/localStorage";
import moment from "moment-timezone";
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_DATABASE_TIMEZONE,
    DEFAULT_FRONTEND_DATETIME_FORMAT
} from "../../util/util-constants";
import {getProp} from "./util-helpers";
import {FRONTEND_OCR_TIMESTAMP_FORMAT} from "./util-consts";

export function toFrontDateTimeWithoutUserFormat(date) {
    const validationDate = moment(date)
    return (date && validationDate.isValid()) ? moment(date, DEFAULT_DATABASE_DATETIME_FORMAT).format(DEFAULT_FRONTEND_DATETIME_FORMAT) : ""
}

export function currentDate() {
    return moment().format(getProp(LocalStorage.get('user'), "Contact.DateTimeFormat", "MM/DD/YYYY"));
}

export function currentDateTimeToUtc() {
    return moment().tz(DEFAULT_DATABASE_TIMEZONE).format(DEFAULT_DATABASE_DATETIME_FORMAT);
}

export function toFrontDateTimeFromUNIX(dateEntered) {
    const fromUnixToDateTime = moment.unix(dateEntered).format(DEFAULT_DATABASE_DATETIME_FORMAT)

    return toFrontDateTimeFromUTC(fromUnixToDateTime)
}

export function toLocalTimeFromUTC(date) {
    if (!date) return "";

    return moment.utc(date, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE).tz(getUserTimezone()).format(DEFAULT_DATABASE_DATETIME_FORMAT);
}

export function toFrontDateTimeFromUTC(dateEntered) {
    return (dateEntered ? moment.utc(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE).tz(getUserTimezone()).format(getUserDateTimeFormat()) : "");
}

export function fromUtcToTz(utcDate, timezone) {
    if (!utcDate) {
        return "";
    }

    if (!timezone) {
        return utcDate;
    }

    return moment
        .utc(utcDate, DEFAULT_DATABASE_DATETIME_FORMAT)
        .tz(timezone).format(getUserDateTimeFormat());
}

export function toFrontDateFromUTC(dateEntered) {
    return (dateEntered ? moment.utc(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE).tz(getUserTimezone()).format(getUserDateTimeFormat()).split(" ")[0] : "");
}

export function getUserTimezone() {
    return LocalStorage.get('user')?.Contact?.Timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getUserDateTimeFormat() {
    return LocalStorage.get('user')?.Contact?.DateTimeFormat ?? "MM/DD/YYYY h:mm A";
}

export function UTCtoUserDate(serverDate) {
    const dateFormat = DEFAULT_DATABASE_DATETIME_FORMAT;
    if (serverDate) {
        return moment.utc(serverDate, dateFormat, DEFAULT_DATABASE_TIMEZONE).tz(getUserTimezone()).format(dateFormat);
    }
}

export function checkFirstBeforeSecondTime(beforeTime, afterTime) {
    const timeFormat = getUserTimeFormat();
    const beforeFormattedTime = moment(beforeTime, timeFormat);
    const afterFormattedTime = moment(afterTime, timeFormat);

    return beforeFormattedTime.isAfter(afterFormattedTime);
}

export function toFrontTimeFromUTC(dateEntered) {
    let format = LocalStorage.get('user')?.Contact.DateTimeFormat.split(" ")
    format.shift()
    format = format.join(" ")
    return (dateEntered ?
            moment
                .utc(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE)
                .tz(getUserTimezone())
                .format(format)
            :
            ""
    );
}

export function toServerTime(userFormattedTime) {
    if (!userFormattedTime) {
        return undefined;
    }

    return moment(userFormattedTime, getUserTimeFormat()).format(DEFAULT_DATABASE_DATETIME_FORMAT);
}

export function toFrontDateTime(dateEntered) {
    return (dateEntered ? moment(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT).format(getUserDateTimeFormat()) : "");
}

export function toFrontDate(dateEntered) {
    return ((dateEntered && dateEntered > "1900-01-01 00:00:00") ? moment(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT).format(getUserDateTimeFormat().split(" ")[0]) : "");
}

export function toFrontDayMonth(dateEntered) {
    return ((dateEntered && dateEntered > "1900-01-01 00:00:00") ? moment(dateEntered).format("MMMM Do") : "");
}

export function toBackDateTime(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000" || dateEntered === "1900-01-01 00:00:00") {
        return "no data available";
    }
    return (dateEntered ? moment(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT).format(DEFAULT_DATABASE_DATETIME_FORMAT) : "no data available");
}

export function toBackDate(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000") {
        return "no data available";
    }
    return (dateEntered ? moment(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT).format("YYYY-MM-DD") : "no data available");
}

export function toFrontDateNoTime(dateEntered) {
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD').format("MM/DD/YYYY") : "");
}

export function toBackDateNoTime(dateEntered) {
    if (dateEntered === "no data available" || dateEntered === "" || dateEntered === null) {
        return "";
    } else {
        return (dateEntered ? moment(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT).format("YYYY-MM-DD") : "");
    }
}

export function toCurrentDateTime(dateEntered) {
    if (dateEntered) {
        return (dateEntered ? moment(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT).format(LocalStorage.get("user").Contact?.DateTimeFormat) : "")
    }
}

export function getLocalTimezone() {
    return moment.tz.guess()
}

export function getDateOnly(date) {
    let tmp = date.split(" ")
    return tmp[0]
}

export function getTimeOnly(date) {
    let tmp = date.split(" ")
    if (tmp.length > 2) {
        return `${tmp[1]} ${tmp[2]}`
    } else {
        return `${tmp[1]}`
    }
}

export function convertTime12to24(time12h) {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
        hours = '00';
    }
    if (modifier === 'PM' || modifier === 'pm') {
        hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}:00`;
}

export function convertTime24to12(time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? ' AM' : ' PM';
        time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
}

export function enumerateDaysBetweenDates(startDate, endDate) {
    let dates = [];
    startDate = moment.utc(startDate, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE)
        .tz(getUserTimezone())
    endDate = moment.utc(endDate, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE)
        .tz(getUserTimezone())
    let currDate = moment(startDate).startOf('day');
    let lastDate = moment(endDate).startOf('day');

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().toDate());
    }
    return dates;
}

export function isMilitaryTimeSet() {
    if (LocalStorage.get('user')?.Contact?.DateTimeFormat) {
        return LocalStorage.get('user')?.Contact.DateTimeFormat.slice(-1) !== "A"
    }
}

export function getUserTimeFormat() {
    if (isMilitaryTimeSet()) {
        return "HH:mm"
    } else {
        return "hh:mma"
    }
}

export const timeFromUTCToFront = date => {
    if (date) {
        return moment.utc(date, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE).tz(getUserTimezone()).format(getUserTimeFormat())
    }

    return date
}

export function isTodayDate(date, translate) {
    let value = moment(date).isSame(moment(), 'day')
    if (value) {
        let tmp = toFrontDateTimeFromUTC(date).split(" ")
        tmp.shift()
        tmp = tmp.join(" ")
        return `${translate("text.Today_at")} ${tmp}`
    } else {
        return toFrontDateTimeFromUTC(date)
    }
}

export function guessDateFormat(date) {
    let separator = "";
    const dateFormats = [
        {format: 'MM/DD/YYYY', separator: '/', dayIndex: 1, monthIndex: 0},
        {format: 'DD/MM/YYYY', separator: '/', dayIndex: 0, monthIndex: 1},
        {format: 'MM/DD/YY', separator: '/', dayIndex: 1, monthIndex: 0},
        {format: 'DD/MM/YY', separator: '/', dayIndex: 0, monthIndex: 1},
        {format: 'MM-DD-YYYY', separator: '-', dayIndex: 1, monthIndex: 0},
        {format: 'DD-MM-YYYY', separator: '-', dayIndex: 0, monthIndex: 1},
        {format: 'MM-DD-YY', separator: '-', dayIndex: 1, monthIndex: 0},
        {format: 'DD-MM-YY', separator: '-', dayIndex: 0, monthIndex: 1},
        {format: 'MM.DD.YYYY', separator: '.', dayIndex: 1, monthIndex: 0},
        {format: 'DD.MM.YYYY', separator: '.', dayIndex: 0, monthIndex: 1},
        {format: 'MM.DD.YY', separator: '.', dayIndex: 1, monthIndex: 0},
        {format: 'DD.MM.YY', separator: '.', dayIndex: 0, monthIndex: 1}
    ];

    if (date.search("/") > 0) {
        separator = "/"
    }

    if (date.search("-") > 0) {
        separator = "-"
    }

    if (date.search(".") > 0) {
        separator = "."
    }

    if (separator) {
        const formatList = dateFormats.reduce((memo, it) => {
            if (it.separator === separator) {
                memo.push(it);
            }
            return memo;
        }, []);

        for (const format of formatList) {
            const parsedDate = parseGuessedDate(date, format.format, separator);
            if (parsedDate !== null) {
                const guessedDay = parsedDate.getDate();
                const guessedMonth = parsedDate.getMonth() + 1;
                if (guessedDay === parseInt(date.split(separator)[format.dayIndex]) &&
                    guessedMonth === parseInt(date.split(separator)[format.monthIndex])) {
                    return format.format;
                }
            }
        }
    }
}

function parseGuessedDate(date, format, separator) {
    const parts = date.split(separator);
    const formatParts = format.split(separator);

    if (parts[2].length !== formatParts[2].length) {
        return null;
    }

    if (parts[2].length === 2) {
        parts[2] = "20" + parts[2];
    }

    if (parts.length !== formatParts.length) {
        return null;
    }

    const dateObject = {};
    for (let i = 0; i < formatParts.length; i++) {
        dateObject[formatParts[i]] = parseInt(parts[i]);
    }

    const month = dateObject['MM'] - 1; // JS months are zero-based
    const day = dateObject['DD'];
    const year = dateObject[formatParts[2]]

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
        return null;
    }

    return new Date(year, month, day);
}

export function fromUserTzToUTC(userLocalTime) {
    return moment.tz(userLocalTime, DEFAULT_DATABASE_DATETIME_FORMAT, getUserTimezone()).utc().format(DEFAULT_DATABASE_DATETIME_FORMAT);
}

export function timeZoneToUTC(date) {

    let timezone = LocalStorage.get("user")?.Contact?.Timezone

    if (!date || !timezone || !moment(date, DEFAULT_DATABASE_DATETIME_FORMAT).isValid()) return undefined;


    const m = moment.tz(date, DEFAULT_DATABASE_DATETIME_FORMAT, timezone);

    m.utc();

    return m.format(DEFAULT_DATABASE_DATETIME_FORMAT);
}

export function getTimeFromServerDate(date) {
    let dateFormat = "HH:mm:ss";

    if (date?.length > 8) {
        dateFormat = "yyyy-mm-dd HH:mm:ss";
    }

    if (!date || date === 'Invalid date') {
        date = "";
    }

    if (moment(date, dateFormat).format(getUserTimeFormat()) === 'Invalid date') {
        return date;
    }

    return moment(date, dateFormat).format(getUserTimeFormat());
}

export function timePickerValueToServerTime(time) {
    if (!time) return "00:00:00";


    return moment(time, getUserTimeFormat()).format("HH:mm:ss");
}

export function getCurrentTimeSeconds() {
    const d = new Date();
    return Math.round(d.getTime() / 1000);
}

export function toFrontDateTimeOCR(dateEntered) {
    return (dateEntered ? moment.utc(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE).tz(getUserTimezone()).format(FRONTEND_OCR_TIMESTAMP_FORMAT) : "");
}
