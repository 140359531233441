import React, {Component, useState} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {fieldsToHtml, fillFieldsFromData} from '../../../common/util/util-fields';
import {getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import Tippy from '@tippyjs/react'
import moment from 'moment-timezone'
import {
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    NUMBER_OF_AXLES,
    READ_PERM,
    SCAC_CODE_FIELD_LENGTH,
    TRUCK_STATUS_AVAILABLE,
    TRUCK_STATUS_BOOKED,
    TRUCK_STATUS_ON_LOAD,
    TRUCK_STATUS_OUT_OF_SERVICE,
    TRUCK_STATUS_RETIRED,
    TRUCK_STATUS_SOLD,
    TRUCK_STATUS_TYPE_TIPPY_TEXT,
    TRUCK_STATUS_TYPES,
    UPDATE_PERM,
    VIN_CODE_FIELD_LENGTH
} from '../../../common/util/util-consts'
import {
    checkPerm,
    classNames,
    getLookup,
    getProp, resourceIsUpdated,
    returnMileage,
    returnMileageText,
    returnSameKeyValueObject
} from '../../../common/util/util-helpers'
import {toFrontDate} from '../../../common/util/util-dates'
import {scrollErrorIntoViewFields} from '../../../common/util/util-vanilla'
import {
    ArrowTopRightOnSquareIcon,
    InformationCircleIcon,
    PlayIcon,
    StopIcon,
    TagIcon
} from "@heroicons/react/24/outline";
import {getHistoryResource} from "../../../data/actions/historyResource";
import {showModal} from "../../../data/actions/ui";
import GroupsViewDialog from "../../my-company/groups-view/groups-view-dialog";
import {HourglassIcon} from "../../../data/themes/icons";
import Card from "../../../common/components/card";
import CardSubTitle from "../../../common/components/card/card-sub-title";
import {LoaderLarge, LoaderSmall} from "../../../common/components/loader";
import * as PropTypes from "prop-types";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Modal from "../../../common/components/modal";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import FieldOptions from "../../../common/components/fields/field-options";
import Badge from "../../../common/components/badge";
import ContactInfoDialog from "../../../common/components/modal/contact-info-dialog";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import TableTagManagerPopover
    from "../../../common/components/resource-table/table-components/table-tag-manager/table-tag-manager-popover";
import PopOver from "../../../common/components/popover";
import {createDialogResource, getDialogResource, updateDialogResource} from "../../../data/actions/dialogResource";
import TableTagManagerModal
    from "../../../common/components/resource-table/table-components/table-tag-manager/table-tag-manager-modal";

Card.propTypes = {
    addClass: PropTypes.string,
    children: PropTypes.node
};
export default class InfoTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            canSubmit: false,

            // Modals
            isRetiredModalOpen: false,

            isGroupInfoDialogOpen: false,
            groupInfoData: {},
            isTagManagerModalOpen: false
        }

        this.fieldsRef = {}
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData();
        this.fetchLabels();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, 'resource.data', {}))
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.TrucksInfo,
            query: this.getQuery()
        }))
    }

    fetchLabels = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.ColorTag
        }));
    }

    onSubmit = () => {
        if (this.state.canSubmit) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.setState({canSubmit: false}, () => {
                        this.props.canSwapTabsHandler(true)
                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                                id: this.getID(),
                                WarrantyMileage: this.state.fields.WarrantyMileage.value ? this.state.fields.WarrantyMileage.value.replace(',', '') : ''
                            }),
                            query: this.getQuery(),
                            errorMessage: true,
                            successMessage: `Truck ${getProp(this.props.resource, 'data.SCAC', '')} updated`,
                            resource: Resources.TrucksInfo,
                            piggyResource: Resources.TrucksInfo,
                            infoPiggyResource: Resources.TrucksInfo
                        }))
                    })
                } else {
                    scrollErrorIntoViewFields([this.state.fields])
                }
            })
        }
    }

    onIsRetiredModalSubmit = (params = {}) => {
        params.TruckID = this.getID()
        params.IsRetired = getProp(this.props, 'resource.data.IsRetired', 0) ? 0 : 1
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            params: params,
            resource: Resources.TruckRetire,
            request: "PUT",
            query: this.getQuery(),
            piggyResource: Resources.TrucksInfo,
            errorMessage: true,
            successMessage: params.IsRetired ? "Truck Retired Successfully!" : "Truck is set as active!"
        }))
        this.handleToggleRetiredModal()
    }

    /** UI Events
     ================================================================= */
    handleToggleRetiredModal = () => {
        this.setState({isRetiredModalOpen: !this.state.isRetiredModalOpen})
    }

    handleInputChange = (name, value, nextElementRef) => {
        if (nextElementRef) {
            this.fieldsRef[nextElementRef].focus()
        }

        if (!this.state.canSubmit) {
            this.props.canSwapTabsHandler()
        }

        const fields = FieldsManager.updateField(this.state.fields, name, value)
        if (name === "OwnedByID") {
            fields["OwnedByContactID"].value = ''
            fields["OwnedByOrganizationID"].value = ''

            fields["OwnedByContactID"].disabled = value === 2
            fields["OwnedByOrganizationID"].disabled = value === 1
        }

        if (name === 'OwnedByCompany') {
            fields['OwnedByID'].disabled = !!value
            fields['OwnedByContactID'].disabled = !!value
            fields['OwnedByOrganizationID'].disabled = true
            fields['TruckOwnershipTypeID'].disabled = !!value
            fields['OwnedByID'].value = null
            fields['OwnedByContactID'].value = null
            fields['OwnedByOrganizationID'].value  = null
            fields['TruckOwnershipTypeID'].value  = null
        }

        this.setState({canSubmit: true, fields: fields})
    }

    handleFormCancel = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                fields: this.getFields(getProp(this.props, 'resource.data', {}))
            }, () => this.props.canSwapTabsHandler(true))
        }
    }

    handleGroupInfoClick = (groupData) => {
        this.setState({
            isGroupInfoDialogOpen: true,
            groupInfoData: groupData
        })
    }

    handleContactInfoClick = (id) => {
        this.setState({selectedContactID: id, isContactInfoDialogOpen: true})
    }

    handleOrganizationInfoClick = (id) => {
        this.setState({selectedOrganizationID: id, isOrganizationInfoDialogOpen: true})
    }

    handleTagLoad = (selectedLabels) => {
        const IDs = [this.getID()]
        const ColorTag = selectedLabels.filter(it => it.checked).reduce((memo, it, index) => {
            memo = memo + (index ? '|' : '') + it.label + ',' + it.color
            return memo
        }, '')

        this.props.dispatch(updateDialogResource({
            user: LocalStorage.get('user'),
            params: {IDs, ColorTag},
            resource: Resources.TrucksColor,
            query: {},
            errorMessage: true,
            successMessage: this.props.translate('text.tags_applied'),
        }));
    }

    createTag = (label, color) => {
        this.props.dispatch(createDialogResource({
            user: LocalStorage.get('user'),
            params: {ColorTag: label + ',' + color},
            resource: Resources.ColorTag,
            piggyResource: Resources.ColorTag,
            errorMessage: true,
            successMessage: this.props.translate('text.tag_created'),
        }))
    }

    toggleTagManagerModal = () => {
        this.setState({isTagManagerModalOpen: !this.state.isTagManagerModalOpen})
    }

    /** Fields/Data Definitions
     ================================================================= */
    getFields = (item = {}) => {
        const fieldTemplates = {
            SCAC: new Field('SCAC', '', [''], false, 'text', {addContainerClass: 'col-span-3 md:col-span-2'}, {
                upperCaseOnly: true,
                max: SCAC_CODE_FIELD_LENGTH
            }),
            TruckNumber: new Field('TruckNumber', '', ['empty'], false, 'text', {addContainerClass: 'col-span-3 md:col-span-2'}),
            Year: new Field('Year', '', [''], false, 'select', {addContainerClass: 'col-span-3 md:col-span-2'}),
            TruckLicenceNumber: new Field('TruckLicenceNumber', '', [''], false, 'text', {addContainerClass: 'col-span-3'}),
            TruckLicenceStateID: new Field('TruckLicenceStateID', '', [''], false, 'select', {addContainerClass: 'col-span-3'}, {
                values: getLookup('State')
            }),
            Manufacturer: new Field('Manufacturer', '', [''], false, 'text', {addContainerClass: 'col-span-2'}),
            Model: new Field('Model', '', [''], false, 'text', {addContainerClass: 'col-span-2'}),
            Engine: new Field('Engine', '', [''], false, 'text', {addContainerClass: 'col-span-2'}),
            OfficeID: new Field('OfficeID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            ContactGroupID: new Field('ContactGroupID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-3', label: "FleetGroupID",
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.handleGroupInfoClick(it?.value?.metadata),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }, {
                isClearable: true,
                api: 'api/' + Resources.ContactGroupsQuick,
                query: {},
                searchMap: (it) => ({
                    label: it.ContactGroupName,
                    value: it.ContactGroupID,
                    metadata: it
                })
            }),
            VIN: new Field('VIN', '', ['length_===_17'], false, 'text', {addContainerClass: 'col-span-3'}, {
                max: VIN_CODE_FIELD_LENGTH,
                upperCaseOnly: true,
                numbersAndLettersOnly: true
            }),
            CarbID: new Field('CarbID', '', [''], false, 'text', {addContainerClass: 'col-span-3'}),
            WarrantyMileage: new Field('WarrantyMileage', '', ['float_or_empty'], false, 'text', {addContainerClass: 'col-span-3'}),
            WarrantyDate: new Field('WarrantyDate', '', [''], false, 'date', {addContainerClass: 'col-span-3'}),

            IsNonIFTA: new Field('IsNonIFTA', '', [''], false, 'checkbox', {addContainerClass: 'col-span-3'}),
            TruckUnloadedWeight: new Field('TruckUnloadedWeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TruckGrossWeight: new Field('TruckGrossWeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TruckNumberOfAxles: new Field('TruckNumberOfAxles', '', [''], false, 'select', {addContainerClass: 'col-span-3'}, {
                values: NUMBER_OF_AXLES
            }),
            TruckWidth: new Field('TruckWidth', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TruckHeight: new Field('TruckHeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),

            Notes: new Field('Notes', '', [], false, 'textarea', {
                heightClass: 'h-52',
                addContainerClass: 'col-span-3'
            }),
            AssetCompanyStatusID: new Field('AssetCompanyStatusID', '', [], false, 'select', {}, {isClearable: true}),
            AssignmentNotes: new Field('AssignmentNotes', '', [], false, 'textarea', {
                heightClass: 'h-52',
                addContainerClass: 'col-span-3'
            }),
            TruckUtilizationStatusID: new Field('TruckUtilizationStatusID', '', [], false, 'select', {}, {isClearable: true}),
            TruckUtilizationNotes: new Field('TruckUtilizationNotes', '', [], false, 'textarea', {addContainerClass:'col-span-full'}),

            OwnedByCompany: new Field('OwnedByCompany', '', [], false, 'checkbox', {addContainerClass: 'col-span-6'}),
            TruckOwnershipTypeID: new Field('TruckOwnershipTypeID', '', [''], !!item.OwnedByCompany, 'select', {addContainerClass: 'col-span-6'}, {isClearable: true}),
            OwnedByID: new Field('OwnedByID', !!item.OwnedByCompany ? '' : 1, [''], !!item.OwnedByCompany, 'button-group', {
                data: {
                    1: this.props.translate('field.OwnedByContactID'),
                    2: this.props.translate('field.OwnedByOrganizationID')
                },
                addContainerClass: 'col-span-6',
            }),
            OwnedByContactID: new Field('OwnedByContactID', '', [''], true, 'select-search', {
                addContainerClass: 'col-span-3',
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.handleContactInfoClick(it.value.value),
                                    isVisible: !!it?.value?.value
                                }
                            ]}
                        />
                    )
                }
            }, {isClearable: true}),
            OwnedByOrganizationID: new Field('OwnedByOrganizationID', '', [''], true, 'select-search', {
                addContainerClass: 'col-span-3',
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.handleOrganizationInfoClick(it.value.value),
                                    isVisible: !!it?.value?.value
                                }
                            ]}
                        />
                    )
                }
            }, {isClearable: true}),

            CurrentMarkerValue: new Field('CurrentMarkerValue', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            LastCurrentMarkerValueDate: new Field('LastCurrentMarkerValueDate', '', [''], false, 'date', {addContainerClass: 'col-span-3'}),
        }

        if (item) {
            if (!item.OwnedByContactID && !item.OwnedByOrganizationID) fieldTemplates["OwnedByContactID"].disabled = true
            if (item.OwnedByContactID) {
                fieldTemplates["OwnedByID"].value = 1
                fieldTemplates["OwnedByContactID"].disabled = false
            }
            if (item.OwnedByOrganizationID) {
                fieldTemplates["OwnedByID"].value = 2
                fieldTemplates["OwnedByOrganizationID"].disabled = false
            }
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getFieldTypes = () => {
        return Object.values(this.getFields()).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})
    }

    /** Query Definitions
     ================================================================= */
    getQuery = () => {
        return {
            id: this.getID()
        }
    }

    getID = () => {
        return this.props.match.params.id
    }

    /** Helpers
     ================================================================= */
    getTruckStatusBadgeClass = (type) => {
        switch (type) {
            case TRUCK_STATUS_AVAILABLE:
                return "success"
            case TRUCK_STATUS_BOOKED:
            case TRUCK_STATUS_ON_LOAD:
                return "primary"
            case TRUCK_STATUS_OUT_OF_SERVICE:
                return "warning"
            case TRUCK_STATUS_SOLD:
            case TRUCK_STATUS_RETIRED:
                return "danger"
            default:
                return "danger"
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, historyResource} = this.props
        const {
            StatusID,
            RetiredNotes,
            OnLoadID,
            LastActive,
            Mileage,
            IsRetired
        } = getProp(this.props, "resource.data", {})
        const isLoading = this.props.resource.isLoading;

        const selects = {
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            Year: returnSameKeyValueObject(1900, moment().year() + 1),
            TrackingMethodID: getLookup('TrackingMethod'),
            OwnedByOrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.OrganizationID,
                    label: item.LegalName
                })
            },
            OwnedByContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + " " + item.LastName
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
        };
        const fieldsHtmlBasicInfo = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(0, 13), translate, this.handleInputChange, selects)
        const fieldsHtmlTaxProfile = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(13, 20), translate, this.handleInputChange, selects);
        const fieldsHtmlNotes = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(20, 25), translate, this.handleInputChange, selects);
        const fieldsHtmlOwnership = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(25, 32), translate, this.handleInputChange, selects);

        const data = getProp(this.props.resource, 'data', [])
        const ColorLabels = getProp(this.props.dialogResource, 'data', []);

        const taggedRows = String(data.ColorTag).split("|").reduce((memo, it) => {
            memo[it] = [1]
            return memo;
        }, {});

        return (
            <React.Fragment>
                <React.Fragment>
                    <div className={'flex justify-end items-center mb-3 ml-4'}>
                        <div className={"relative"}>
                            {!!isLoading && (
                                <LoaderSmall/>
                            )}

                            <div
                                className={
                                    classNames(
                                        "flex justify-end items-center space-x-3",
                                        !!isLoading && "invisible"
                                    )
                                }
                            >
                                <PopOver
                                    btnClass={"text-opacity-90 btn btn-header"}
                                    tippy={translate("text.label_truck")}
                                    BtnIcon={TagIcon}
                                    btnIconClass="text-tm-gray-600 w-5 h-5"
                                    widthClass={'max-w-[16rem]'}
                                    positionClass="fixed md:absolute right-0 mx-5 md:mx-0 translate-x-0"
                                >
                                    <div
                                        className="bg-popup border border-tm-gray-300 rounded-md"
                                    >
                                        <TableTagManagerPopover
                                            translate={translate}
                                            labels={ColorLabels}
                                            taggedRows={{
                                                list: taggedRows
                                            }}
                                            selectedRows={{[1]: 1}}
                                            onApplySelected={(taggedRows, labelList) => this.handleTagLoad(labelList)}
                                            onManageTagsClick={this.toggleTagManagerModal}
                                            isLoading={false}
                                        />
                                    </div>
                                </PopOver>

                                <button
                                    onClick={() => this.props.setActiveTab(Resources.TrucksMileage)}
                                    className={`btn btn-xs btn-outline py-1.5`}>
                                    Mileage: {(returnMileage(Mileage))} {returnMileageText() ?? translate("text." + returnMileageText())}
                                </button>
                                <div className={'flex justify-end ml-6'}>
                                    <Tippy content={
                                        <span>{(IsRetired ? "Truck is in the retired state. Click to change status back to active." : "Truck is in active state. Click to change status to retired state.")}</span>}>
                                        <button
                                            type="button"
                                            onClick={this.handleToggleRetiredModal}
                                            className="btn btn-header mr-2"
                                        >
                                            {IsRetired ?
                                                <PlayIcon className={'w-5 h-5 text-green-600'}/>
                                                :
                                                <StopIcon className={'w-5 h-5 text-red-600'}/>
                                            }
                                        </button>
                                    </Tippy>
                                </div>
                                <Tippy
                                    content={
                                        <span>{translate(TRUCK_STATUS_TYPE_TIPPY_TEXT[StatusID], [RetiredNotes])}</span>}
                                >
                                    <div>
                                        <Badge
                                            addClass="h-8 items-center"
                                            onClick={() => {
                                                if (StatusID === TRUCK_STATUS_BOOKED || StatusID === TRUCK_STATUS_ON_LOAD) {
                                                    window.open(`${window.location.origin}/loads/info/${OnLoadID}`, '_blank');
                                                }
                                            }}
                                            type={this.getTruckStatusBadgeClass(StatusID)}>
                                            <div className="flex">
                                                {TRUCK_STATUS_TYPES[StatusID]}
                                                {(StatusID === TRUCK_STATUS_BOOKED || StatusID === TRUCK_STATUS_ON_LOAD) && (
                                                    <ArrowTopRightOnSquareIcon
                                                        className={'w-5 h-5 ml-4'}
                                                    />
                                                )}
                                            </div>
                                        </Badge>
                                    </div>
                                </Tippy>
                                {LastActive && (
                                    <button
                                        onClick={() => this.props.setActiveTab(Resources.TrucksLoads)}
                                        className={`btn btn-xs active btn-light`}>
                                        Last load on: {toFrontDate(LastActive)}
                                    </button>
                                )}

                                {
                                    checkPerm(Resources.TrucksHistory, READ_PERM) && (
                                        <div className={'flex justify-end ml-6'}>
                                            <Tippy content={<span>{translate('text.view_history')}</span>}>
                                                <button
                                                    type="button"
                                                    onClick={() => this.setState({historyModalOpen: true})}
                                                    className="btn btn-header"
                                                >
                                                    {<HourglassIcon className="w-5 h-5"/>}
                                                </button>
                                            </Tippy>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="pb-20">
                        <div className="grid grid-cols-12 gap-4 max-w-4xl 3xl:max-w-full mx-auto 3xl:mx-0">
                            <div className="col-span-full 3xl:col-span-6">
                                <Card addClass={'h-full relative'}>
                                    <CardSubTitle
                                        text={translate('text.BasicInfo')}
                                    />
                                    <div className="grid grid-cols-6 gap-5">
                                        {fieldsHtmlBasicInfo}
                                    </div>

                                    {isLoading && (
                                        <LoaderLarge stripesBg/>
                                    )}
                                </Card>
                            </div>
                            <div className="col-span-full 3xl:col-span-6">
                                <Card addClass={'h-full'}>
                                    <CardSubTitle
                                        text={translate('text.Notes')}
                                    />
                                    <div className="space-y-5">
                                        {fieldsHtmlNotes}
                                    </div>

                                    {isLoading && (
                                        <LoaderLarge stripesBg/>
                                    )}
                                </Card>
                            </div>

                            <div className="col-span-full 3xl:col-span-6">
                                <Card addClass={'h-full'}>
                                    <CardSubTitle
                                        text={translate('text.Profile')}
                                    />
                                    <div className="grid grid-cols-6 gap-5">
                                        {fieldsHtmlTaxProfile}
                                    </div>

                                    {isLoading && (
                                        <LoaderLarge stripesBg/>
                                    )}
                                </Card>
                            </div>

                            <div className="col-span-full 3xl:col-span-6">
                                <Card addClass={'h-full'}>
                                    <CardSubTitle
                                        text={translate('text.OwnerShipAndValue')}
                                    />
                                    <div className="grid grid-cols-6 gap-5">
                                        {fieldsHtmlOwnership}
                                    </div>

                                    {isLoading && (
                                        <LoaderLarge stripesBg/>
                                    )}
                                </Card>
                            </div>
                        </div>
                    </div>

                    <PageFooter
                        translate={translate}
                        canSubmit={this.state.canSubmit}
                        actionCancel={this.handleFormCancel}
                        actionSubmit={checkPerm(Resources.TrucksInfo, UPDATE_PERM) && this.onSubmit}
                    />
                </React.Fragment>

                <ModalSaveResource
                    show={this.state.isRetiredModalOpen && !IsRetired}
                    title={"Set Truck as inactive/retired"}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleRetiredModal}
                    fields={{
                        RetiredNotes: new Field("RetiredNotes", '', ['empty'], false, 'textarea', {
                            addContainerClass: "col-span-full"
                        })
                    }}
                    onSubmit={(params) => {
                        if (params) {
                            this.onIsRetiredModalSubmit(params)
                        }
                    }}
                    translate={translate}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!(this.state.isRetiredModalOpen && IsRetired)}
                    text={"Are you sure you want to set this truck to active state?"}
                    onClose={this.handleToggleRetiredModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => this.onIsRetiredModalSubmit()}
                />

                <Modal
                    show={this.state.historyModalOpen}
                    widthClass={"max-w-5xl"}
                    onClose={() => this.setState({historyModalOpen: false})}
                    translate={translate}
                >
                    {this.state.historyModalOpen && (
                        <HistoryModal
                            onClose={() => this.setState({historyModalOpen: false})}
                            translate={translate}
                            title={translate("text." + Resources.TrucksHistory)}
                            tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                            filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                            onFetchData={(query) => {
                                this.props.dispatch(getHistoryResource({
                                    user: LocalStorage.get('user'),
                                    query: Object.assign({}, query, {
                                        id: this.getID()
                                    }),
                                    resource: Resources.TrucksHistory
                                }))
                            }}
                            data={getProp(historyResource, "data.list", [])}
                            count={getProp(historyResource, "data.count", 0)}
                            isLoading={getProp(historyResource, "isLoading", false)}
                            showUserAvatar

                            onRowClick={(item) => {
                                this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                            }}
                            fieldTypes={this.getFieldTypes()}
                        />
                    )}
                </Modal>

                <GroupsViewDialog
                    groupID={this.state.fields?.ContactGroupID?.value?.value}
                    isGroupInfoDialogOpen={this.state.isGroupInfoDialogOpen}
                    groupInfoData={this.state.groupInfoData}
                    onClose={() => this.setState({
                        isGroupInfoDialogOpen: false
                    })}
                    translate={translate}
                />

                {(this.state.isContactInfoDialogOpen &&
                    <ContactInfoDialog
                        show={this.state.isContactInfoDialogOpen}
                        translate={translate}
                        contactID={this.state.selectedContactID}
                        handleClose={() => this.setState({
                            isContactInfoDialogOpen: false
                        })}
                    />
                )}

                {(this.state.isOrganizationInfoDialogOpen &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationInfoDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganizationID}
                        handleClose={() => this.setState({
                            isOrganizationInfoDialogOpen: false
                        })}
                    />
                )}

                <TableTagManagerModal
                    show={this.state.isTagManagerModalOpen}
                    onClose={this.toggleTagManagerModal}
                    translate={translate}
                    onCreateTag={this.createTag}
                    tagsData={ColorLabels}
                    isLoading={this.props.dialogResource.isLoading}
                />
            </React.Fragment>
        )
    }

}
